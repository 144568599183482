import * as React from 'react';
import { useMemo, useState } from 'react';
import BaseLayout from '../components/Layouts/BaseLayout';
import * as portfolioJson from '../data/portfolio.json';
import { Link } from 'gatsby';
import BackgroundImage from '../components/BackgroundImage/BackgroundImage';
import { Container } from 'reactstrap';
import Masonry from "react-masonry-css";

export const PortfolioPageInternal = ({ collections, location }) => {
  const [ selectedCollection, setSelectedCollection ] = useState();
  const pieces = useMemo(() => {
    if(!selectedCollection) {
      return collections.map(c => c.pieces).flat();
    }

    return selectedCollection.pieces;
  }, [ selectedCollection ]);

  return (
    <BaseLayout location={location} title="Portfolio">
      <header className="page-header small bg-secondary">
        <Container>
          <div className="header-content text-center">
            <h1 className="header-title bg-transparent">PORTFOLIO</h1>
          </div>
        </Container>
      </header>

      <ul className="portfolio-filter list-inline text-center filter-bg bg-secondary">
        <a
          className={!selectedCollection ? 'active ml-2' : ''}
          href="#"
          onClick={
            (e) => {
              e.preventDefault();
              setSelectedCollection(undefined);
            }
          }
        >
          ALL
        </a>
        {
          collections.map((collection) => (
            <li key={collection.label}>
              <a
                className={selectedCollection?.label === collection.label ? 'active ml-2' : ''}
                href="#"
                onClick={
                  (e) => {
                    e.preventDefault();
                    setSelectedCollection(collection);
                  }
                }
              >
                {collection.label?.toUpperCase()}
              </a>
            </li>
          ))
        }
      </ul>

      <section className="big-section px-2">
        <Masonry
          breakpointCols={{
            default: 3,
            500: 1,
          }} className="project-masonry" columnClassName="project-masonry-item"
        >
          {
            pieces.map((piece, i) => {
              const firstImage = piece.images?.[0];

              if(!firstImage) {
                return null;
              }

              return (
                <div key={`${piece.title}_${i}`}>
                  <Link className="portfolio-item " to={`/piece/${piece.id}`}>
                    <div className="hovereffect mb-3">
                      <img className="img-responsive" src={firstImage.src} alt={`Justin Scroggins work titled '${piece.title}'.`}/>
                      {/*<BackgroundImage className="img-responsive" style={{ height: 280 }} alt={`Justin Scroggins work titled '${piece.title}'.`} src={firstImage.src} />*/}
                    </div>
                  </Link>
                </div>
              );
            })
          }
        </Masonry>

        {/*<div className="row">*/}
        {/*  {*/}
        {/*    pieces.map((piece, i) => {*/}
        {/*      const firstImage = piece.images?.[0];*/}

        {/*      if(!firstImage) {*/}
        {/*        return null;*/}
        {/*      }*/}

        {/*      return (*/}
        {/*        <div key={`${piece.title}_${i}`} className="col-md-4 mb-5">*/}
        {/*          <Link className="portfolio-item" to={`/piece/${piece.id}`}>*/}
        {/*            <div className="hovereffect">*/}
        {/*              /!*<img className="img-responsive" src={firstImage.src} alt={`Justin Scroggins work titled '${piece.title}'.`}/>*!/*/}
        {/*              <BackgroundImage className="img-responsive" style={{ height: 280 }} alt={`Justin Scroggins work titled '${piece.title}'.`} src={firstImage.src} />*/}

        {/*              <div className="overlay">*/}
        {/*                <div className="hovereffect-title">*/}
        {/*                  <Link to={`/piece/${piece.id}`}>*/}
        {/*                    <h5 className="project-title">{piece.title}</h5>*/}
        {/*                    <p className="project-skill">PHOTOGRAPHY</p>*/}
        {/*                  </Link>*/}
        {/*                </div>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*          </Link>*/}
        {/*        </div>*/}
        {/*      );*/}
        {/*    })*/}
        {/*  }*/}
        {/*</div>*/}
      </section>
    </BaseLayout>
  );
};

export default ({ location }) => <PortfolioPageInternal location={location} {...portfolioJson} />;
