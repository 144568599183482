import './background-image.scss';

import React from 'react';
import classnames from 'classnames';

const BackgroundImage = ({ style, src, className, ...props }) => {
  return (
    <div
      {...props}
      className={classnames('background-image', className)}
      style={{ ...style, backgroundImage: `url('${src}')` }}
    />
  );
};

export default BackgroundImage;
