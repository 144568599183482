import '../../assets/scss/styles.scss';

import * as React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import MenuListItem from '../MenuListItem/MenuListItem';
import * as siteJson from '../../data/site.json';

const BaseLayout = ({ title, location, children }) => {
  function handleToggle(e) {
    e.preventDefault();
    document.getElementById('wrapper').classList.toggle('toggled');
  }

  return (
    <>
      <Helmet
        defaultTitle="Justin Scroggins"
        titleTemplate="%s | Justin Scroggins">
        <title>{title}</title>

        <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500" rel="stylesheet"/>
        <link href="https://fonts.googleapis.com/css?family=Comfortaa:300,400,700" rel="stylesheet"/>
      </Helmet>

      <div id="wrapper" className="center-sidebar-nav">
        <a
          href="#menu-toggle"
          id="menu-toggle"
          className="navbar-toggler collapsed white-bg"
          onClick={handleToggle}
        >
          <span className="icon-bar top-bar" />
          <span className="icon-bar middle-bar" />
          <span className="icon-bar bottom-bar" />
        </a>
        <div id="sidebar-wrapper" className="sidebar-nav">
          <ul className="sidebar-nav-inner">
            <li className="sidebar-brand">
              <Link to="/">
                <img src={siteJson.logo} alt="Justin Scroggins' Logo" />
              </Link>
            </li>
            <MenuListItem location={location} pathToLookFor="/">
              <Link className="menu-item" to="/">
                HOME
              </Link>
            </MenuListItem>
            <MenuListItem location={location} pathToLookFor="/about">
              <Link className="menu-item" to="/about">
                ABOUT ME
              </Link>
            </MenuListItem>
            <MenuListItem location={location} pathToLookFor="/portfolio">
              <Link className="menu-item" to="/portfolio">
                PORTFOLIO
              </Link>
            </MenuListItem>
            <MenuListItem location={location} pathToLookFor="/cv">
              <Link className="menu-item" to="/cv">
                CV
              </Link>
            </MenuListItem>
            <MenuListItem location={location} pathToLookFor="/contact">
              <Link className="menu-item" to="/contact">
                CONTACT
              </Link>
            </MenuListItem>
            <li className="sidebar-social">
              <a href="https://www.instagram.com/justin.scroggins/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram" />
              </a>
            </li>
          </ul>
        </div>

        <div id="page-content-wrapper">
          {children}
        </div>
      </div>
    </>
  );
};

export default BaseLayout;
