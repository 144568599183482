import * as React from 'react';

const MenuListItem = ({ pathToLookFor, location, children }) => {
  return (
    <li className={location?.pathname === pathToLookFor ? 'active' : ''}>
      {children}
    </li>
  );
};

export default MenuListItem;
